import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-menu-loto',
  templateUrl: './menu-loto.component.html',
  styleUrls: ['./menu-loto.component.css']
})
export class MenuLotoComponent implements OnInit, AfterViewInit {

  _isNameSessionAsking = false;
  _sessionName = '';

  constructor(private router: Router) {
  }

  // to put button back on the bottom of the page
  @ViewChild('backHome')
  private footerContent: ElementRef;

  updatePlacementFooter() {
    const heightFooter = this.footerContent.nativeElement.firstElementChild.firstElementChild.clientHeight;
    $('#content').css('min-height', 0);
    $('#content').css('min-height', ($(document).outerHeight(true) - heightFooter - 470));
  }

  // to hide element according to screen resolution
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePlacementFooter();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.updatePlacementFooter();
  }

  getSessionName() {
    this._isNameSessionAsking = true;
  }

  goFastGame() {
    this.router.navigate(['loto']);
  }

  goNormalGame(sessionName: string) {
    this.router.navigate(['loto/session/' + sessionName]);
  }

  goSummaryGame() {
    this.router.navigate(['loto/summary']);
  }

  goSessionGame() {
    this.router.navigate(['loto']);
  }

  back() {
    this.router.navigate(['home']);
  }


}
