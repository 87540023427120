import { Component, OnInit } from '@angular/core';
import { BoxLoto } from '../model-loto-player/boxLoto';
import { LotoService } from '../service-loto-player/loto.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Loto } from '../model-loto-player/loto';
import { Session } from '../model-loto-player/session';
import { EtatModel } from '../model-loto-player/etatModel';
import { LotoLines } from '../model-loto-player/lotoLines';

@Component({
  selector: 'app-loto-player',
  templateUrl: './loto-player.component.html',
  styleUrls: ['./loto-player.component.css']
})
export class LotoPlayerComponent implements OnInit {

  _line: Loto;
  _styleBackground = '#e3e0cc';
  _styleColor = 'black';
  _styleBackgroundSelected = '#587e76';
  _styleColorSelected = 'white';
  _boxStyle: BoxLoto;
  _paramDisplay = false;
  _displayLogo = false;
  _displaySelected = false;
  _sessionName = 'default';
  _partInProgress = 0;

  _allLines: LotoLines[] = [];

  constructor(
    public lotoService: LotoService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.initLoto();
  }

  initLoto() {
    this._displayLogo = JSON.parse(localStorage.getItem('displayLogo'));
    this.route.params.subscribe(params => {
      this._sessionName = params['sessionName'];
      this.initBoxLotoDemo();
      if (!this._sessionName) {
        this._sessionName = 'default';
      }
      if (!localStorage.getItem(this._sessionName)) {
        localStorage.setItem('result', '');
        this.builderCaseLoto(true);
        this.addNewSession();
      } else {
        this.builderCaseLotoFromLocal();
      }
    });
  }

  /**
   * ajout de la nouvelle session
   */
  addNewSession() {
    let listSession: string[] = [];
    if (!localStorage.getItem('listSession')) {
      listSession = [this._sessionName];
      localStorage.setItem('listSession', JSON.stringify(listSession));
    } else {
      listSession = JSON.parse(localStorage.getItem('listSession'));
      listSession.push(this._sessionName);
      localStorage.setItem('listSession', JSON.stringify(listSession));
    }
  }

  back() {
    this.router.navigate(['loto/menu']);
  }

  /**
   * Permet de créer une session complete ou une partie
   * @param isNew nouvelle session
   */
  builderCaseLoto(isNew: boolean) {
    const loto: Loto = this.builderBox();
    // Regarde si une nouvelle partie doit être créee
    if (!isNew) {
      this.lotoService.setNumber(0);
      this._line.nameSession = loto.nameSession;
      // Récupération des couleurs de l'ancienne partie
      const background = this._line.boxLoto[0].background;
      const color = this._line.boxLoto[0].color;
      const backgroundSelected = this._line.boxLoto[0].backgroundSelected;
      const colorSelected = this._line.boxLoto[0].colorSelected;

      this._line.boxLoto = loto.boxLoto;
      // Mise en place des anciennes couleurs
      this._line.boxLoto.forEach(box => {
        box.background = background;
        box.color = color;
        box.backgroundSelected = backgroundSelected;
        box.colorSelected = colorSelected;
      });
      this.createNewPart();
    } else {
      const newPart: Session = {
        etatModel: EtatModel.unchange,
        numberPart: 1,
        saveNumber: [],
      };
      loto.saveSession.push(newPart);
      this._line = loto;
      this._partInProgress = this.findLastPart();
      localStorage.setItem(this._sessionName, JSON.stringify(this._line));
    }
    this._allLines = [];
    this.buildLotoLine();
  }

  builderBox(): Loto {
    let boxLoto: BoxLoto;
    const loto: Loto = {
      nameSession: this._sessionName,
      boxLoto: [],
      saveSession: []
    };
    // premiere ligne
    let lineModel = [1];
    let placementTable = 0;
    for (let i = 0; i < 90; i++) {
      lineModel.forEach(index => {
        boxLoto = new BoxLoto();
        boxLoto.id = index;
        boxLoto.isSelected = false;
        boxLoto.background = this._styleBackground;
        boxLoto.color = this._styleColor;
        boxLoto.backgroundSelected = this._styleBackgroundSelected;
        boxLoto.colorSelected = this._styleColorSelected;
        boxLoto.name = 'box' + index;
        placementTable++;
        loto.boxLoto.push(boxLoto);
      });
      const temp = [];
      lineModel.forEach(index => {
        index = index + 1;
        temp.push(index);
      });
      lineModel = temp;
    }
    return loto;
  }

  /**
   * Permet de changer les couleurs des boxes
   */
  changeColorBox() {
    this._line.boxLoto.forEach(box => {
      box.background = this._styleBackground;
      box.color = this._styleColor;
      box.backgroundSelected = this._styleBackgroundSelected;
      box.colorSelected = this._styleColorSelected;
    });
    localStorage.setItem(this._sessionName, JSON.stringify(this._line));
  }

  /**
   * Création d'une nouvelle partie
   */
  createNewPart() {
    this._partInProgress = this.findLastPart() + 1;
    const newPart: Session = {
      etatModel: EtatModel.unchange,
      numberPart: this._partInProgress,
      saveNumber: [],
    };
    this._line.saveSession.push(newPart);
    localStorage.setItem(this._sessionName, JSON.stringify(this._line));
  }

  /**
   * Recherche la dernière partie en cours
   */
  findLastPart(): number {
    let lastPart = 0;
    this._line.saveSession.forEach(session => {
      if (lastPart < session.numberPart) {
        lastPart = session.numberPart;
      }
    });
    return lastPart;
  }

  builderCaseLotoFromLocal() {
    this._line = JSON.parse(localStorage.getItem(this._sessionName));
    this.buildLotoLine();
    this._partInProgress = this.findLastPart();
  }

  initBoxLotoDemo() {
    this._boxStyle = new BoxLoto();
    this._boxStyle.id = 1;
    this._boxStyle.isSelected = false;
    this._boxStyle.background = this._styleBackground;
    this._boxStyle.color = this._styleColor;
    this._boxStyle.backgroundSelected = this._styleBackgroundSelected;
    this._boxStyle.colorSelected = this._styleColorSelected;
  }

  changeInputColorBackground(styleBackground: string) {
    this._boxStyle.isSelected = false;
    this._styleBackground = styleBackground;
    this._boxStyle.background = styleBackground;
  }

  changeInputColor(styleColor: string) {
    this._boxStyle.isSelected = false;
    this._styleColor = styleColor;
    this._boxStyle.color = styleColor;
  }

  changeInputColorBackgroundSelected(styleBackgroundSelected: string) {
    this._boxStyle.isSelected = true;
    this._styleBackgroundSelected = styleBackgroundSelected;
    this._boxStyle.backgroundSelected = styleBackgroundSelected;
  }

  changeInputColorSelected(styleColorSelected: string) {
    this._boxStyle.isSelected = true;
    this._styleColorSelected = styleColorSelected;
    this._boxStyle.colorSelected = styleColorSelected;
  }

  parameterDisplay() {
    this._paramDisplay = !this._paramDisplay;
  }

  toggleDisplayLogo(e) {
    localStorage.setItem('displayLogo', JSON.stringify(e.target.checked));
    this._displayLogo = e.target.checked;
  }

  getLinesUpdate(lines: Loto) {
    this._line = lines;
    this._allLines = [];
    this.buildLotoLine();
  }

  quinesDisplay() {
    this._displaySelected = !this._displaySelected;
  }

  buildLotoLine() {
    let index = 0;
    let line: LotoLines = {
      boxLine: []
    };
    this._line.boxLoto.forEach(boxLoto => {
      line.boxLine.push(boxLoto);
      index++;
      if (index === 10) {
        index = 0;
        this._allLines.push(line);
        line = {
          boxLine: []
        };
      }
    });
  }
}
