import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.css']
})
export class CellComponent implements OnInit {

  public classAlive: string;

  @Input()
  public state: number;

    constructor() { }

  ngOnInit() {
    if(this.state === 1) {
      this.classAlive = 'cell alive';
    } else {
      this.classAlive = 'cell dead';
    }
  }

  ngOnChanges() {
  if (this.classAlive === 'cell alive') {
    this.classAlive = 'cell dead';
  } else {
    this.classAlive = 'cell alive';
  }
}

  toggle() {
    // if (this.classAlive === 'cell alive') {
    //   this.classAlive = 'cell dead';
    // } else {
    //   this.classAlive = 'cell alive';
    // }
  }

}
