import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LotoService {

  private numberClicked = 0;

  constructor() { }

  getNumber() {
    if (localStorage.getItem('result') !== '') {
      this.numberClicked = Number(localStorage.getItem('result'));
    }
    if (this.numberClicked !== 0) {
      return this.numberClicked;
    }
    return 0;
  }

  setNumber(number: number) {
    localStorage['result'] = number;
    this.numberClicked = number;
  }
}
