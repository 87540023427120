import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GameLifeComponent } from './game-life/home-game-life/game-life.component';
import { CellComponent } from './game-life/cell/cell.component';
import { LotoPlayerComponent } from './loto-player/home-loto-player/loto-player.component';
import { BlocLotoComponent } from './loto-player/bloc-loto/bloc-loto.component';

import { LotoService } from './loto-player/service-loto-player/loto.service';
import { FooterComponent } from './footer/footer.component';
import { MenuLotoComponent } from './loto-player/menu-loto/menu-loto.component';
import { LotoSummaryComponent } from './loto-player/loto-summary/loto-summary.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GameLifeComponent,
    CellComponent,
    LotoPlayerComponent,
    BlocLotoComponent,
    FooterComponent,
    MenuLotoComponent,
    LotoSummaryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ColorPickerModule],
  providers: [LotoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
