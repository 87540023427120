import {Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';

import {CellLine} from '../model-game-life/cellLine';
import {Cell} from '../model-game-life/cell';

import * as $ from 'jquery';

@Component({
  selector: 'app-game-life',
  templateUrl: './game-life.component.html',
  styleUrls: ['./game-life.component.css']
})
export class GameLifeComponent implements OnInit, AfterViewInit {

  public cellsGrid: CellLine[] = [];
  public aroundX: number[] = [-1, 0, 1, 1, 1, 0, -1, -1];
  public aroundY: number[] = [-1, -1, -1, 0, 1, 1, 1, 0];
  public sizeGrid = 25;
  public nbLoop = 10;
  public isRunning = false;

  // to put navbar on the bottom of the page
  @ViewChild('footer')
  private footerContent: ElementRef;

  updatePlacementFooter() {
    const heightFooter = this.footerContent.nativeElement.firstElementChild.firstElementChild.clientHeight;
    console.log(heightFooter);
    $('#content').css('min-height', 0);
    $('#content').css('min-height', ($(document).outerHeight(true) - heightFooter));
  }

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.initialisationGridCell(this.cellsGrid);
  }

  ngAfterViewInit(): void {
    this.updatePlacementFooter();
  }

  // to hide element according to screen resolution
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePlacementFooter();
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  reset() {
    this.isRunning = false;
    this.nbLoop = 1;
    this.cellsGrid = [];
    this.initialisationGridCell(this.cellsGrid);
  }

  async loop() {
    this.isRunning = true;
    while (this.nbLoop !== 0) {
      await this.delay(300);
      this.runGameLife();
      this.nbLoop--;
    }
    this.nbLoop = 10;
    this.isRunning = false;
  }

  initialisationGridCell(grid: CellLine[]) {
    let numberCells = 0;
    let numberLines = 0;
    while (numberLines !== this.sizeGrid) {
      const cellLine: CellLine = new CellLine();
      const line: Cell[] = [];
      numberCells = 0;
      while (numberCells != this.sizeGrid) {
        const cell: Cell = new Cell();
        cell.life = 0;
        cell.position = [numberCells, numberLines];
        line.push(cell);
        numberCells++;
      }
      cellLine.cells = line;
      grid.push(cellLine);
      numberLines++;
    }
  }

  toggleLifeCell(cell: Cell) {
    if (cell.life === 1) {
      this.cellsGrid[cell.position[1]].cells[cell.position[0]].life = 0;
    } else {
      this.cellsGrid[cell.position[1]].cells[cell.position[0]].life = 1;
    }
  }

  getStateCell(x: number, y: number): number {
    return this.cellsGrid[y].cells[x].life;
  }

  isCellStillAlive(cell: Cell): boolean {
    let stillAlive = false;
    let nbCellAlive = 0;
    let nbNeighbours = 0;
    const x: number = cell.position[0];
    const y: number = cell.position[1];
    while (nbNeighbours !== 8) {
      if (x + this.aroundX[nbNeighbours] >= 0
        && y + this.aroundY[nbNeighbours] >= 0
        && x + this.aroundX[nbNeighbours] !== this.sizeGrid
        && y + this.aroundY[nbNeighbours] !== this.sizeGrid) {
        if (this.getStateCell(x + this.aroundX[nbNeighbours], y + this.aroundY[nbNeighbours])) {
          nbCellAlive++;
        }
      }
      nbNeighbours++;
    }

    if ((nbCellAlive === 2 || nbCellAlive === 3) && this.getStateCell(x, y) === 1) {
      stillAlive = true;
    }

    if (nbCellAlive === 3 && this.getStateCell(x, y) === 0) {
      return true;
    }
    return stillAlive;
  }

  runGameLife() {
    const tempcellsGrid: CellLine[] = [];
    this.initialisationGridCell(tempcellsGrid);
    this.cellsGrid.forEach(function (cellLine: CellLine) {
      cellLine.cells.forEach(function (cell: Cell) {
        const positionX: number = cell.position[0];
        const positionY: number = cell.position[1];
        if (this.isCellStillAlive(cell)) {
          tempcellsGrid[positionY].cells[positionX].life = 1;
        } else {
          tempcellsGrid[positionY].cells[positionX].life = 0;
        }
      }.bind(this));
    }.bind(this));
    this.cellsGrid = tempcellsGrid;
  }

  back() {
    this.router.navigate(['home']);
  }
}
