import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BoxLoto } from '../model-loto-player/boxLoto';

import { LotoService } from '../service-loto-player/loto.service';
import { Loto } from '../model-loto-player/loto';

@Component({
  selector: 'app-bloc-loto',
  templateUrl: './bloc-loto.component.html',
  styleUrls: ['./bloc-loto.component.css']
})
export class BlocLotoComponent implements OnInit {

  @Input() iBoxLoto: BoxLoto;
  @Input() iSessionName: string;
  @Input() iPartInProgress: number;
  @Input() iDisplaySelected: boolean;

  @Output()
  linesUpdate$: EventEmitter<Loto> = new EventEmitter<Loto>();

  classColor: string;

  constructor(private lotoService: LotoService) { }

  ngOnInit() {
  }

  validateBox(index: number) {
    if (this.iSessionName) {
      if (!this.iBoxLoto.isSelected) {
        this.lotoService.setNumber(this.iBoxLoto.id);
      } else {
        this.lotoService.setNumber(0);
      }
      localStorage[this.iSessionName] = (JSON.stringify(this.changeValueFromGrill(index)));
    }
  }

  changeValueFromGrill(index: number): Loto {
    const lines: Loto = JSON.parse(localStorage.getItem(this.iSessionName));
    const partInProgress = lines.saveSession.find(session => session.numberPart === this.iPartInProgress);
    // Vérification si nombre existant dans saveNumber
    if (!partInProgress.saveNumber.find(number => number === index)) {
      partInProgress.saveNumber.push(index);
    } else {
      partInProgress.saveNumber.splice(partInProgress.saveNumber.indexOf(index), 1);
    }

    const boxSelected: BoxLoto = lines.boxLoto.find(box => box.id === index);
    boxSelected.isSelected = !boxSelected.isSelected;
    this.linesUpdate$.emit(lines);
    return lines;
  }
}
