import {Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private router: Router) {
  }

  // to put navbar on the bottom of the page
  @ViewChild('footer')
  private footerContent: ElementRef;
  updatePlacementFooter() {
    const heightFooter = this.footerContent.nativeElement.firstElementChild.firstElementChild.clientHeight;
    $('#content').css('min-height', 0);
    $('#content').css('min-height', ($(document).outerHeight(true) - heightFooter));
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.updatePlacementFooter();
  }

  gameLife() {
    this.router.navigate(['gameLife']);
  }

  lotoPlayer() {
    this.router.navigate(['loto/menu']);
  }

  // to hide element according to screen resolution
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePlacementFooter();
  }
}
