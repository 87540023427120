import { RouterModule, Routes } from '@angular/router';

import { GameLifeComponent } from './game-life/home-game-life/game-life.component';
import { HomeComponent } from './home/home.component';
import { LotoPlayerComponent } from './loto-player/home-loto-player/loto-player.component';
import { LotoSummaryComponent } from './loto-player/loto-summary/loto-summary.component';
import { MenuLotoComponent } from './loto-player/menu-loto/menu-loto.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: '*', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'gameLife', component: GameLifeComponent },
  { path: 'loto', component: LotoPlayerComponent },
  { path: 'loto/session/:sessionName', component: LotoPlayerComponent },
  { path: 'loto/summary', component: LotoSummaryComponent },
  { path: 'loto/menu', component: MenuLotoComponent },
  { path: '**', redirectTo: '/home' } // to redirect all path unknown
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
