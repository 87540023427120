import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';

import * as $ from 'jquery';
import {Loto} from '../model-loto-player/loto';
import {BoxLoto} from '../model-loto-player/boxLoto';
import {EtatModel} from '../model-loto-player/etatModel';
import {LotoLines} from '../model-loto-player/lotoLines';

@Component({
  selector: 'app-loto-summary',
  templateUrl: './loto-summary.component.html',
  styleUrls: ['./loto-summary.component.css']
})
export class LotoSummaryComponent implements OnInit, AfterViewInit {

  _etatDelete = EtatModel.delete;
  _etatUnchange = EtatModel.unchange;
  _etatMiniBingoButton = EtatModel.miniBingoButton;
  _etatAllSelectedButton = EtatModel.allButtonSelected;
  _etatAllDefaultButton = EtatModel.allButtonDefault;

  _allSession: Loto[] = [];
  _isSessionExist: boolean;
  _isDisplaySummaryLoto = false;
  _sessionSelected: Loto;
  _resumeBoxSelected: BoxLoto[] = [];
  _displaySelected = false;
  _indexSelected = 0;
  _buttonAllDisplay: EtatModel;

  _allLines: LotoLines[] = [];


  constructor(private router: Router) {
  }

  // to put button back on the bottom of the page
  @ViewChild('backHome')
  private footerContent: ElementRef;

  updatePlacementFooter() {
    const heightFooter = this.footerContent.nativeElement.firstElementChild.firstElementChild.clientHeight;
    $('#content').css('min-height', 0);
    $('#content').css('min-height', ($(document).outerHeight(true) - heightFooter - 20));
  }

  // to hide element according to screen resolution
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePlacementFooter();
  }

  ngOnInit() {
    this.getAllSession();
    this._buttonAllDisplay = this._etatMiniBingoButton;
  }

  ngAfterViewInit(): void {
    this.updatePlacementFooter();
  }

  back() {
    if (this._isDisplaySummaryLoto) {
      this._isDisplaySummaryLoto = false;
    } else {
      this.router.navigate(['loto/menu']);
    }
  }

  getAllSession() {
    const listName: string[] = JSON.parse(localStorage.getItem('listSession'));
    if (!listName || listName.length === 0) {
      this._isSessionExist = false;
    } else {
      this._isSessionExist = true;
      this._allSession = [];
      listName.forEach(name => {
        this._allSession.push(JSON.parse(localStorage.getItem(name)));
      });
    }
  }

  getDisplayLotoSummary(sessionName: string) {
    this._isDisplaySummaryLoto = true;
    this._sessionSelected = this._allSession.find(session => session.nameSession === sessionName);
    this.miniBingoDisplay();
  }

  quinesDisplay() {
    this._displaySelected = !this._displaySelected;
  }

  /**
   * Initialise une grille de loto
   */
  initLoto(boxes: BoxLoto[]) {
    boxes.forEach(box => {
      box.isSelected = false;
    });
  }

  /**
   * Récupère tous les numéros de la session
   */
  getAllNumberBox() {
    this._buttonAllDisplay = this._etatAllSelectedButton;
    this._indexSelected = 0;
    if (this._sessionSelected) {
      const listNumber: number[] = [];
      // Récupération de tous les numéros
      this._sessionSelected.saveSession.forEach(part => {
        part.etatModel = EtatModel.unchange;
        part.saveNumber.forEach(number => {
          if (!listNumber.find(el => el === number)) {
            listNumber.push(number);
          }
        });
      });

      // Mise en place des 'boxes' selectionnées
      this.setBoxesSelected(listNumber);
    }
  }

  /**
   * Affiche les numéros bingo
   */
  miniBingoDisplay() {
    this._buttonAllDisplay = this._etatMiniBingoButton;
    this._indexSelected = 0;
    if (this._sessionSelected) {
      const listNumber: number[] = [];
      // Récupération de tous les numéros
      this._sessionSelected.saveSession.forEach(part => {
        part.etatModel = EtatModel.unchange;
        // Récupération du premier et du dernier
        const firstNum = part.saveNumber[0];
        const lastNum = part.saveNumber[part.saveNumber.length - 1];
        if (!listNumber.find(el => el === firstNum)) {
          listNumber.push(firstNum);
        }
        if (!listNumber.find(el => el === lastNum)) {
          listNumber.push(lastNum);
        }
      });

      // Mise en place des 'boxes' selectionnées
      this.setBoxesSelected(listNumber);
    }
  }

  /**
   * Affiche les numéros bingo d'une partie
   */
  getMiniBingoOneNumberBox(index: number) {
    this._buttonAllDisplay = EtatModel.allButtonDefault;
    this._indexSelected = index;
    const listNumber: number[] = [];
    this.initLoto(this._resumeBoxSelected);
    // Récupération des numéros de la partie selectionnée
    const partSelected = this._sessionSelected.saveSession.find(part => part.numberPart === index);
    // Récupération du premier et du dernier
    const firstNum = partSelected.saveNumber[0];
    const lastNum = partSelected.saveNumber[partSelected.saveNumber.length - 1];
    if (!listNumber.find(el => el === firstNum)) {
      listNumber.push(firstNum);
    }
    if (!listNumber.find(el => el === lastNum)) {
      listNumber.push(lastNum);
    }
    // Mise en place des 'boxes' selectionnées
    this.setBoxesSelected(listNumber);
    this.displayDeletePart(index);
  }

  /**
   * Récupère tous les numéros d'une partie
   * @param index numéro de la partie
   */
  getOneNumberBox(index: number) {
    this._buttonAllDisplay = EtatModel.allButtonDefault;
    this._indexSelected = 0;
    const listNumber: number[] = [];
    this.initLoto(this._resumeBoxSelected);
    // Récupération des numéros de la partie selectionnée
    this._sessionSelected.saveSession.find(part => part.numberPart === index).saveNumber.forEach(number => {
      if (!listNumber.find(el => el === number)) {
        listNumber.push(number);
      }
    });
    // Mise en place des 'boxes' selectionnées
    this.setBoxesSelected(listNumber);
    this.displayDeletePart(index);
  }

  /**
   * Permet d'afficher le deuxième bouton pour effacer
   * @param index numéro de la partie
   */
  displayDeletePart(index: number) {
    this._sessionSelected.saveSession.forEach(part => {
      if (part.numberPart === index) {
        part.etatModel = EtatModel.delete;
      } else {
        part.etatModel = EtatModel.unchange;
      }
    });
  }

  /**
   * Mise en place des 'boxes' selectionnées
   * @param listNumber liste des nombres
   */
  setBoxesSelected(listNumber: number[]) {
    this._resumeBoxSelected = this._sessionSelected.boxLoto;
    this.initLoto(this._resumeBoxSelected);
    this._resumeBoxSelected.forEach(box => {
      if (listNumber.find(numberSelected => numberSelected === box.id)) {
        box.isSelected = true;
      }
    });
    this._allLines = [];
    this.buildLotoLine();

  }

  /**
   * Efface une session
   */
  deleteSession() {
    localStorage.removeItem(this._sessionSelected.nameSession);
    const listName: string[] = JSON.parse(localStorage.getItem('listSession'));
    listName.splice(listName.indexOf(this._sessionSelected.nameSession), 1);
    localStorage.setItem('listSession', JSON.stringify(listName));
    this.router.navigate(['loto/menu']);
  }

  /**
   * Efface une partie
   * @param index index de la partie à effacer
   */
  erasePart(index: number) {
    if (this._sessionSelected.saveSession.length !== 1) {
      // suppression de la partie et sauvegarde
      this._sessionSelected.saveSession = this._sessionSelected.saveSession.filter(part => part.numberPart !== index);
      localStorage.setItem(this._sessionSelected.nameSession, JSON.stringify(this._sessionSelected));

      // sauvegarde de la nouvelle grille sauvegarde
      // recherche de la dernière partie
      let max = 0;
      this._sessionSelected.saveSession.forEach(part => {
        if (max < part.numberPart) {
          max = part.numberPart;
        }
      });

      // initialisation de toutes les boxes
      this._sessionSelected.boxLoto.forEach(box => {
        box.isSelected = false;
      });

      this._sessionSelected.boxLoto.forEach(box => {
        if (this._sessionSelected.saveSession.find(part => part.numberPart === max).saveNumber.find(num => num === box.id)) {
          box.isSelected = true;
        }
      });

      // sauvegarde de la grille
      localStorage.setItem(this._sessionSelected.nameSession, JSON.stringify(this._sessionSelected));

    } else {
      this.deleteSession();
    }
  }

  buildLotoLine() {
    let index = 0;
    let line: LotoLines = {
      boxLine: []
    };
    this._resumeBoxSelected.forEach(boxLoto => {
      line.boxLine.push(boxLoto);
      index++;
      if (index === 10) {
        index = 0;
        this._allLines.push(line);
        line = {
          boxLine: []
        };
      }
    });
  }
}
